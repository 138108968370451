import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { first, isNil, last } from 'lodash-es';

import { IconColor, IconComponent, IconName } from '../icon';
import { UserMenuItem, UserMenuVariant } from './user-menu.types';

@Component({
  selector: 'etn-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IconComponent, MatButtonModule, MatMenuModule],
})
export class UserMenuComponent implements OnChanges {
  @Input() public userName: string | Nil;
  @Input() public items: UserMenuItem[] = [];

  @HostBinding('class')
  @Input()
  public variant: UserMenuVariant = UserMenuVariant.Avatar;

  @HostBinding('class.opened')
  public opened = false;

  public chevronIcon: IconName = 'expand_more';
  public iconColor: IconColor = IconColor.Primary;
  public accountIcon: IconName = 'account_circle';

  public avatarVariant = UserMenuVariant.Avatar;
  public avatarName: string | Nil;

  public ngOnChanges({ userName }: SimpleChanges): void {
    if (isNotNil(userName)) {
      this.avatarName = this.getAvatarName(this.userName);
    }
  }

  @HostListener('mouseover')
  public onMouseOver() {
    this.iconColor = IconColor.PrimaryContrast;
  }

  @HostListener('mouseout')
  public onMouseOut() {
    if (!this.opened) {
      this.iconColor = IconColor.Primary;
    }
  }

  public onMenuOpened(): void {
    this.opened = true;
    this.chevronIcon = 'expand_less';
  }

  public onMenuClosed(): void {
    this.opened = false;
    this.iconColor = IconColor.Primary;
    this.chevronIcon = 'expand_more';
  }

  public onUserMenuItemClick(item: UserMenuItem): void {
    if (isNotNil(item.callback)) {
      item.callback();
    }
    this.opened = false;
  }

  private getAvatarName(accountName: string | Nil): string {
    if (isNil(accountName) || accountName.length === 0) {
      return '';
    }

    const parts = accountName
      .split(' ')
      .map((part) => {
        return part.toUpperCase().trim();
      })
      .filter((part) => {
        return part.length > 0;
      });

    if (parts.length === 0) {
      return 'NA';
    }

    const firstLetter = first(parts)?.charAt(0).toUpperCase() ?? '';

    if (parts.length === 1) {
      return firstLetter?.toUpperCase();
    }

    const secondLetter = last(parts)?.charAt(0).toUpperCase() ?? '';

    return `${firstLetter}${secondLetter}`;
  }
}
