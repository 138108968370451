import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MESSAGES } from '@i18n/evd';
import { Nil } from '@model';
import { Location } from '@model/evd/locations';
import { LatLng } from '@model/geography';
import { ListItemSeparatorComponent } from '@ui/list-item';
import { UiService } from '@ui/ui.service';
import { map } from 'rxjs';

import { LocationCardMessages } from '../../../../locations/components/location-card';
import {
  LocationCardCarouselComponent,
  LocationCardCarouselDirection,
} from '../../../../locations/components/location-card-carousel';

@Component({
  selector: 'app-dashboard-page-locations-panel',
  imports: [
    CommonModule,
    ListItemSeparatorComponent,
    LocationCardCarouselComponent,
  ],
  templateUrl: './dashboard-page-locations-panel.component.html',
  styleUrl: './dashboard-page-locations-panel.component.scss',
})
export class DashboardPageLocationsPanelComponent {
  public constructor(private uiService: UiService) {}

  public locations = input.required<Location[]>();
  public userLatLng = input<LatLng | Nil>();

  public locationView = output<Location>();
  public locationSwipe = output<Location>();

  public messages = MESSAGES.dashboardPage;

  public locationCardMessages: LocationCardMessages = {
    ...this.messages,
    button: MESSAGES.general.button,
    unit: MESSAGES.general.unit,
  };

  public carouselDirection = toSignal(
    this.uiService.mobileView$.pipe(
      map((isMobile) => {
        return isMobile
          ? LocationCardCarouselDirection.Horizontal
          : LocationCardCarouselDirection.Vertical;
      }),
    ),
  );
}
