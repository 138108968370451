import { CommonModule } from '@angular/common';
import { Component, Signal, computed, input } from '@angular/core';
import {
  ListLocationEvse,
  getLocationEvseDisplayName,
} from '@model/evd/locations';
import { IconComponent, IconName } from '@ui/icon';

import { LocationEvseConnectorsComponent } from '../location-evse-connectors';
import { LocationEvsePricingComponent } from '../location-evse-pricing';
import { LocationEvseMessages } from './location-evse.types';

@Component({
  selector: 'app-location-evse',
  imports: [
    CommonModule,
    LocationEvseConnectorsComponent,
    LocationEvsePricingComponent,
    IconComponent,
  ],
  templateUrl: './location-evse.component.html',
  styleUrl: './location-evse.component.scss',
})
export class LocationEvseComponent {
  public evse = input.required<ListLocationEvse>();
  public messages = input.required<LocationEvseMessages>();

  public name: Signal<string> = computed(() => {
    return getLocationEvseDisplayName(this.evse());
  });

  public chevronIcon: IconName = 'chevron_right';
}
