import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Signal,
  computed,
  signal,
  viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { AUTH_TOKEN_RESOLVE_PROPERTY } from '@core/evd/resolvers';
import { getData, getOptionalData } from '@core/evd/utils';
import { isNotNil } from '@core/is-not-nil';
import { MESSAGES } from '@i18n/evd';
import { MapContainerComponent } from '@layout/map-container';
import { Nil } from '@model';
import { ListEmspContract } from '@model/evd/emsp';
import { ListLocationEvse, Location } from '@model/evd/locations';
import { LatLng } from '@model/geography';
import { Action, makeAccentAction } from '@ui/action';
import { EmptyStateComponent } from '@ui/empty-state';
import { IconName } from '@ui/icon';
import { ListItemComponent } from '@ui/list-item';
import { AutoFitBounds, MapDataSourceItemData, MapMessages } from '@ui/map';
import { isNil } from 'lodash-es';

import { CONTRACTS_RESOLVE_PROPERTY } from './dashboard-page.contracts-resolver';
import { USER_LAT_LNG_RESOVE_PROPERTY } from './dashboard-page.resolver';
import { DashboardPageService } from './dashboard-page.service';
import { DashboardPageLocationEvsePanelComponent } from './location-evse-panel/dashboard-page-location-evse-panel.component';
import { DashboardPageLocationPanelComponent } from './location-panel/dashboard-page-location-panel.component';
import { DashboardPageLocationsPanelComponent } from './locations-panel/dashboard-page-locations-panel.component';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MapContainerComponent,
    DashboardPageLocationsPanelComponent,
    DashboardPageLocationPanelComponent,
    DashboardPageLocationEvsePanelComponent,
    EmptyStateComponent,
  ],
  providers: [DashboardPageService, ListItemComponent],
})
export default class DashboardPageComponent implements OnInit, OnDestroy {
  public constructor(
    private service: DashboardPageService,
    private route: ActivatedRoute,
  ) {}

  public mapContainer = viewChild.required<MapContainerComponent>(
    MapContainerComponent,
  );

  public userLatLng: LatLng | Nil = getOptionalData(
    this.route,
    USER_LAT_LNG_RESOVE_PROPERTY,
  );

  public contracts: ListEmspContract[] | Nil = getOptionalData(
    this.route,
    CONTRACTS_RESOLVE_PROPERTY,
  );

  public token: string = getData(this.route, AUTH_TOKEN_RESOLVE_PROPERTY);

  public locationsDataSource = toSignal(this.service.locationsDataSource$);
  public locations = toSignal(this.service.locations$);

  public location = signal<Location | Nil>(undefined);
  public evse = signal<ListLocationEvse | Nil>(undefined);

  public autoFitBounds = isNil(this.userLatLng)
    ? AutoFitBounds.Once
    : AutoFitBounds.Never;

  public messages = MESSAGES.dashboardPage;

  public mapMessages: MapMessages = {
    search: this.messages.placeholder.enterAddress,
  };

  public actions: Signal<Action[]> = computed(() => {
    const locations = this.locations();
    if (
      isNil(locations) ||
      locations.length === 0 ||
      isNil(this.contracts) ||
      this.contracts.length <= 1
    ) {
      return [];
    }

    return [
      makeAccentAction({
        id: 'filters',
        icon: 'filter_list',
        callback: () => {
          this.service.openFilters();
        },
      }),
    ];
  });

  public emptyStateIcon: IconName = 'description';

  public ngOnInit(): void {
    this.service.initialize(this.token, this.contracts ?? []);
  }

  public ngOnDestroy(): void {
    this.service.destroy();
  }

  public onLocationView(location: Location): void {
    this.location.set(location);
    this.service.setLocation(location);

    if (isNotNil(location.coordinates)) {
      this.mapContainer().setCenter(
        new LatLng(
          location.coordinates.latitude,
          location.coordinates.longitude,
        ),
      );
    }
  }

  public onLocationSwipe(location: Location): void {
    if (isNotNil(location.coordinates)) {
      this.mapContainer().setCenter(
        new LatLng(
          location.coordinates.latitude,
          location.coordinates.longitude,
        ),
      );
    }
  }

  public onLocationBack(): void {
    this.location.set(undefined);
  }

  public onEvseView(evse: ListLocationEvse): void {
    this.evse.set(evse);
    this.service.setEvse(evse);
  }

  public onEvseBack(): void {
    this.evse.set(undefined);
  }

  public onItemSelect(item: MapDataSourceItemData | Nil): void {
    if (isNotNil(item)) {
      this.onLocationView(item as Location);
    }
  }
}
