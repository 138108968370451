import { CommonModule } from '@angular/common';
import { Component, Signal, computed, input } from '@angular/core';
import {
  LocationEvseContract,
  hasTransactionStartLink,
} from '@model/evd/locations';
import { Icon, IconColor, IconComponent } from '@ui/icon';
import { LanguageService } from '@ui/language';

import { LocationEvseContractPricingComponent } from '../location-evse-contract-pricing/location-evse-contract-pricing.component';
import { LocationEvseContractMessages } from './location-evse-contract.types';

@Component({
  selector: 'app-location-evse-contract',
  imports: [CommonModule, LocationEvseContractPricingComponent, IconComponent],
  templateUrl: './location-evse-contract.component.html',
  styleUrl: './location-evse-contract.component.scss',
  host: {
    '[class.selected]': 'selected()',
    '[class.disabled]': 'disabled()',
  },
})
export class LocationEvseContractComponent {
  public constructor(private languageService: LanguageService) {}

  public contract = input.required<LocationEvseContract>();
  public messages = input.required<LocationEvseContractMessages>();
  public selected = input<boolean>();

  public disabled = computed(() => {
    return !hasTransactionStartLink(this.contract());
  });

  public name: Signal<string> = computed(() => {
    return this.languageService.getValue(this.contract().name);
  });

  public selectedIcon: Icon = {
    name: 'check_circle',
    color: IconColor.Primary,
  };
}
