import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MatLegacyMenuModule as MatMenuModule,
  MatLegacyMenuTrigger as MatMenuTrigger,
} from '@angular/material/legacy-menu';
import { Nil } from '@model';
import { Icon, IconComponent, IconSize } from '@ui/icon';
import { SelectableItem } from '@ui/selectable-item';

@Component({
  selector: 'etn-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, CommonModule, MatMenuModule, MatButtonModule],
})
export class LanguagePickerComponent {
  @Input() public currentLanguage: SelectableItem | Nil;
  @Input() public languages: SelectableItem[] | Nil;

  @Output() public languageChange = new EventEmitter<SelectableItem>();

  @ViewChild(MatMenuTrigger) private matMenuTrigger: MatMenuTrigger | Nil;

  public icon: Icon = {
    name: 'arrow_drop_down',
    size: IconSize.Default,
  };
}
