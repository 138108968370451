import { Direction, Directionality } from '@angular/cdk/bidi';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  signal,
} from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ThreeLinerModule } from '@brightlayer-ui/angular-components';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { LanguagePickerComponent } from '@ui/language-picker';
import { SelectableItem } from '@ui/selectable-item';
import { debounce } from 'lodash-es';

import { Action } from '../action';
import { IconSize } from '../icon';
import { UserMenuComponent, UserMenuItem } from '../user-menu';

@Component({
  selector: 'etn-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    LanguagePickerComponent,
    MatToolbarModule,
    ThreeLinerModule,
    UserMenuComponent,
  ],
})
export class ToolbarComponent implements AfterViewInit {
  public constructor(private dir: Directionality) {}

  @Input() public accountName: string | Nil;
  @Input() public accountActions: UserMenuItem[] = [];
  @Input() public title: string | Nil;
  @Input() public hideAccountMenu: boolean | Nil;

  @Input() public currentLanguage: SelectableItem | Nil;
  @Input() public languages: SelectableItem[] | Nil;

  @Output() public languageChange = new EventEmitter<SelectableItem>();

  @ViewChild('titleContainer', { static: true }) public titleContainer:
    | ElementRef
    | Nil;

  @HostBinding('class')
  public direction: Direction = this.dir.value;

  public open = false;
  public avatarValue = '';
  public menuTitle = '';
  public hasAccountName = false;
  public userMenuIconSize = IconSize.Default;
  public titleContainerWidth = signal(0);

  public ngAfterViewInit(): void {
    // we debounce the update of the title container width to avoid
    // too much calls to the updateTitleContainerWidth method
    const debounced = debounce(this.updateTitleContainerWidth.bind(this), 100);

    // we observe the title container to update its width when it changes
    const observer = new ResizeObserver(() => {
      debounced();
    });
    observer.observe(this.titleContainer?.nativeElement);
  }

  public onUserMenuActionClick(action: Action): void {
    if (isNotNil(action.callback)) {
      action.callback();
    }
    this.open = false;
  }

  private updateTitleContainerWidth(): void {
    this.titleContainerWidth.set(
      this.titleContainer?.nativeElement.offsetWidth - 16,
    );
  }
}
