import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MobileStepperModule } from '@brightlayer-ui/angular-components';

import { MobileStepperVariant } from './mobile-stepper.types';

@Component({
  selector: 'etn-mobile-stepper',
  imports: [CommonModule, MobileStepperModule],
  templateUrl: './mobile-stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileStepperComponent {
  public steps = input.required<number>();
  public activeStep = input.required<number>();
  public variant = input<MobileStepperVariant>();

  public defaultVariant = MobileStepperVariant.Dots;
}
