import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { InfoListItemModule } from '@brightlayer-ui/angular-components';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  AbstractFormFieldComponent,
  CONTROL_CONTAINER_VIEW_PROVIDER,
  FORM_FIELD_APPEARANCE,
  HIDE_REQUIRED_MARKER,
} from '@ui/form';
import { IconComponent } from '@ui/icon';
import { SelectableItem } from '@ui/selectable-item';
import { findIndex } from 'lodash-es';

import { CheckboxGroupDisplay } from './checkbox-group.types';

@UntilDestroy()
@Component({
  selector: 'etn-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  providers: [
    {
      provide: AbstractFormFieldComponent,
      useExisting: forwardRef(() => {
        return CheckboxGroupComponent;
      }),
    },
  ],
  viewProviders: [CONTROL_CONTAINER_VIEW_PROVIDER],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IconComponent,
    InfoListItemModule,
    MatCheckboxModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
})
export class CheckboxGroupComponent
  extends AbstractFormFieldComponent<string[]>
  implements OnInit
{
  public constructor(
    private formBuilder: FormBuilder,
    container: ControlContainer,
    @Optional()
    @Inject(FORM_FIELD_APPEARANCE)
    public override appearance: MatFormFieldAppearance,
    @Optional()
    @Inject(HIDE_REQUIRED_MARKER)
    public override hideRequiredMarker: boolean,
  ) {
    super(container, appearance, hideRequiredMarker);
  }

  @HostBinding('class')
  @Input()
  public display: CheckboxGroupDisplay = CheckboxGroupDisplay.Horizontal;

  @Input() public dense = false;
  @Input() public divider = true;
  @Input() public set values(values: SelectableItem[] | Nil) {
    this.setupOptions(values);
  }
  @Input() public lastDivider = false;

  @Output() public checkboxClick = new EventEmitter<void>();

  public optionsValues: SelectableItem[] = [];
  public options = this.formBuilder.array([]);
  public checkboxFormGroup = this.formBuilder.group({
    options: this.options,
  });
  public verticalDisplay = CheckboxGroupDisplay.Vertical;

  public ngOnInit(): void {
    this.updateValue(this.value);
    this.formControl.valueChanges.subscribe((value: string[] | Nil) => {
      this.updateValue(value);
    });
  }

  public onCheckboxChange(): void {
    const value: string[] = [];

    this.options.controls.forEach((control, index) => {
      if (control.value === true && isNotNil(this.optionsValues[index])) {
        value.push(`${this.optionsValues[index].id}`);
      }
    });

    this.formControl.markAsDirty();
    this.formControl.setValue(value);
  }

  private updateValue(value: string[] | Nil): void {
    this.options.controls.forEach((control) => {
      control.setValue(false);
    });
    value?.forEach((id) => {
      const index = findIndex(this.optionsValues, { id });
      if (index >= 0) {
        this.options.controls[index].setValue(true);
      }
    });
  }

  private setupOptions(values: SelectableItem[] | Nil): void {
    this.optionsValues = values ?? [];
    this.options.clear();

    this.options.controls.forEach((control) => {
      control.setValue(false);
      control.enable();
    });

    (values ?? []).forEach((value, index) => {
      this.options.insert(
        index,
        new FormControl({
          value: (this.value ?? []).includes(`${value.id}`),
          disabled: this.formControl.disabled ?? value.disabled ?? false,
        }),
      );
    });
  }

  public onCheckboxClick(): void {
    if (this.formControl.enabled) {
      this.checkboxClick.emit();
    }
  }
}
