import { CommonModule } from '@angular/common';
import { Component, OnInit, output, signal } from '@angular/core';
import { watchUserLatLng } from '@core/geography';
import { Nil } from '@model';
import { LatLng } from '@model/geography';
import { Action, ActionComponent, makeAccentAction } from '@ui/action';
import { isNil } from 'lodash-es';

@Component({
  selector: 'etn-user-position-action',
  imports: [CommonModule, ActionComponent],
  templateUrl: './user-position-action.component.html',
})
export class UserPositionActionComponent implements OnInit {
  public actionClick = output<void>();

  public action = signal<Action | Nil>(undefined);

  public ngOnInit(): void {
    watchUserLatLng((latLng) => {
      this.action.set(this.getAction(latLng));
    });
  }

  private getAction(latLng: LatLng | Nil): Action | Nil {
    if (isNil(latLng)) {
      return undefined;
    }

    return makeAccentAction({
      id: 'center',
      icon: 'near_me',
      callback: () => {
        this.actionClick.emit();
      },
    });
  }
}
