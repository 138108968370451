import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  InfoListItemModule,
  ScoreCardModule,
} from '@brightlayer-ui/angular-components';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import {
  Action,
  ActionColor,
  ActionComponent,
  ActionMenuComponent,
  ActionMenuItem,
} from '@ui/action';
import { IconComponent, IconSize } from '@ui/icon';
import { isNil } from 'lodash-es';

@Component({
  selector: 'etn-score-card',
  imports: [
    ActionComponent,
    ActionMenuComponent,
    CommonModule,
    ScoreCardModule,
    InfoListItemModule,
    IconComponent,
  ],
  templateUrl: './score-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreCardComponent implements OnChanges {
  @Input() public headerTitle: string | Nil;
  @Input() public headerSubtitle: string | Nil;
  @Input() public headerInfo: string | Nil;
  @Input() public actions: Action[] | Nil;
  @Input() public menu: ActionMenuItem[] = [];
  @Input() public rowAction: Action | Nil;

  public menuAction: Action | Nil;
  public cardActions: Action[] | Nil;

  public ngOnChanges({ menu, actions }: SimpleChanges): void {
    if (isNotNil(menu)) {
      this.menuAction = this.getMenuAction(this.menu);
    }
    if (isNotNil(actions)) {
      this.cardActions = this.getActions(this.actions);
    }
  }

  public onRowActionClick() {
    if (this.rowAction && this.rowAction.callback) {
      this.rowAction.callback();
    }
  }

  private getActions(actions: Action[] | Nil): Action[] | Nil {
    if (isNil(actions)) {
      return undefined;
    }
    return actions?.map((action) => {
      return {
        ...action,
        color: ActionColor.Primary,
        iconSize: IconSize.Default,
      };
    });
  }

  private getMenuAction(menu: ActionMenuItem[]): Action | Nil {
    if (isNil(menu)) {
      return undefined;
    }
    return {
      id: 'score-card-menu',
      icon: 'more_vert',
      iconSize: IconSize.Default,
      color: ActionColor.Primary,
    };
  }
}
