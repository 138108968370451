import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { isNotNil } from '@core/is-not-nil';
import { Address, Nil } from '@model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AutocompleteComponent } from '@ui/autocomplete';
import {
  AbstractFormFieldComponent,
  CONTROL_CONTAINER_VIEW_PROVIDER,
} from '@ui/form';
import { SelectableItem } from '@ui/selectable-item';
import { isNil } from 'lodash-es';
import { Observable, ReplaySubject, map, of, switchMap, tap } from 'rxjs';

import { deserializeAddress } from './address-picker.utils';

@UntilDestroy()
@Component({
  selector: 'etn-address-picker',
  templateUrl: './address-picker.component.html',
  styleUrls: ['./address-picker.component.scss'],
  imports: [AutocompleteComponent, CommonModule],
  providers: [
    {
      provide: AbstractFormFieldComponent,
      useExisting: forwardRef(() => {
        return AddressPickerComponent;
      }),
    },
  ],
  viewProviders: [CONTROL_CONTAINER_VIEW_PROVIDER],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressPickerComponent
  extends AbstractFormFieldComponent<Address>
  implements OnInit
{
  @ViewChild(AutocompleteComponent, { static: true }) public autocomplete:
    | AutocompleteComponent
    | Nil;

  private autocompleteService = new google.maps.places.AutocompleteService();
  private placeService = new google.maps.places.PlacesService(
    document.createElement('div'),
  );
  private searchCriteriaSubject$ = new ReplaySubject<string | Nil>(1);

  public values$: Observable<SelectableItem[] | Nil> =
    this.searchCriteriaSubject$.pipe(
      switchMap((criteria) => {
        if (isNil(criteria)) {
          return of(null);
        }
        return this.getPredictions(criteria);
      }),
      map((predictions) => {
        return this.getSelectableItems(predictions, this.formControl.value);
      }),
    );

  public ngOnInit(): void {
    this.searchCriteriaSubject$.next(null);
    if (this.formControl.hasValidator(Validators.required)) {
      this.autocomplete?.formControl.addValidators(Validators.required);
    }
    if (this.formControl.disabled) {
      this.autocomplete?.formControl.disable();
    }

    /**
     * Everytime the value changes we need to update
     * the autocomplete values in order to add the current value
     */
    this.formControl.valueChanges
      .pipe(
        tap(() => {
          this.searchCriteriaSubject$.next(null);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  public onSearch(criteria: string | Nil) {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.searchCriteriaSubject$.next(criteria);
  }

  public onClear() {
    this.formControl.setValue(null);
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.searchCriteriaSubject$.next(null);
  }

  public onValueSelect(placeId: string): void {
    this.placeService.getDetails(
      {
        placeId,
        fields: ['address_components', 'formatted_address', 'geometry'],
      },
      (place: google.maps.places.PlaceResult | null) => {
        if (isNotNil(place) && isNotNil(place.address_components)) {
          const address: Address | Nil = deserializeAddress({
            ...place,
            place_id: placeId,
          });
          this.formControl.setValue(address);
        }
      },
    );
  }

  protected override onValidatorsAdd(validators: ValidatorFn | ValidatorFn[]) {
    this.autocomplete?.formControl.addValidators(validators);
    this.autocomplete?.formControl.updateValueAndValidity();
  }
  protected override onValidatorsRemove(
    validators: ValidatorFn | ValidatorFn[],
  ) {
    this.autocomplete?.formControl.removeValidators(validators);
    this.autocomplete?.formControl.updateValueAndValidity();
  }
  protected override onDisable(opts?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }) {
    this.autocomplete?.formControl.disable(opts);
    this.autocomplete?.formControl.updateValueAndValidity();
  }
  protected override onEnable(opts?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }) {
    this.autocomplete?.formControl.enable(opts);
    this.autocomplete?.formControl.updateValueAndValidity();
  }

  private getPredictions(
    input: string,
  ): Observable<google.maps.places.AutocompletePrediction[] | null> {
    return new Observable((subsriber) => {
      this.autocompleteService.getPlacePredictions(
        { input, types: ['address'] },
        (
          predictions: google.maps.places.AutocompletePrediction[] | null,
          _status: google.maps.places.PlacesServiceStatus,
        ) => {
          subsriber.next(predictions);
        },
      );
    });
  }

  private getSelectableItems(
    predictions: google.maps.places.AutocompletePrediction[] | null,
    currentValue: Address | Nil,
  ): SelectableItem[] | Nil {
    if (isNil(predictions) && isNotNil(currentValue)) {
      return [
        {
          id: currentValue.id,
          name: currentValue.formattedAddress ?? '',
        },
      ];
    }
    return predictions?.map((prediction) => {
      return this.getSelectableItem(prediction);
    });
  }

  private getSelectableItem(
    prediction: google.maps.places.AutocompletePrediction,
  ): SelectableItem {
    return {
      id: String(prediction.place_id),
      name: prediction.description,
    };
  }
}
