import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Signal,
  computed,
  input,
  output,
  signal,
} from '@angular/core';
import { Nil } from '@model';
import { Location } from '@model/evd/locations';
import { LatLng } from '@model/geography';
import { MobileStepperComponent } from '@ui/mobile-stepper';
import { isNil, throttle } from 'lodash-es';

import { LocationCardComponent } from '../location-card/location-card.component';
import {
  LocationCardCarouselDirection,
  LocationCardCarouselMessages,
} from './location-card-carousel.types';

@Component({
  selector: 'app-location-card-carousel',
  imports: [CommonModule, LocationCardComponent, MobileStepperComponent],
  templateUrl: './location-card-carousel.component.html',
  styleUrl: './location-card-carousel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'direction()',
  },
})
export class LocationCardCarouselComponent implements OnInit {
  public constructor(public elementRef: ElementRef) {}

  public locations = input.required<Location[]>();
  public messages = input.required<LocationCardCarouselMessages>();
  public userLatLng = input<LatLng | Nil>();
  public direction = input.required<LocationCardCarouselDirection>();

  public view = output<Location>();
  public swipe = output<Location>();

  @HostListener('window:resize', [])
  public onResize(): void {
    this.width.set(this.elementRef.nativeElement.offsetWidth);
  }

  public isVertical = computed(() => {
    return this.direction() === LocationCardCarouselDirection.Vertical;
  });

  private width = signal<number | Nil>(undefined);

  public currentCardIndex = signal<number>(0);
  public locationsStyles: Signal<Record<string, string> | Nil> = computed(
    () => {
      const width = this.width();
      const index = this.currentCardIndex();
      if (isNil(width) || this.isVertical()) {
        return undefined;
      } else {
        return {
          width: `${this.locations().length * (width + 32)}px`,
          'margin-left': `${-(index * width) + index * 16}px`,
        };
      }
    },
  );
  public locationStyles: Signal<Record<string, string> | Nil> = computed(() => {
    const width = this.width();
    if (isNil(width) || this.isVertical()) {
      return undefined;
    } else {
      return { width: `${width - 32}px` };
    }
  });

  public ngOnInit(): void {
    this.width.set(this.elementRef.nativeElement.offsetWidth);

    const hammertime = new Hammer(this.elementRef.nativeElement);

    hammertime.on(
      'panleft',
      throttle(this.moveToNextCard.bind(this), 1000, { trailing: false }),
    );
    hammertime.on(
      'panright',
      throttle(this.moveToPreviousCard.bind(this), 1000, { trailing: false }),
    );
  }

  private moveToNextCard(): void {
    if (
      !this.isVertical() &&
      this.currentCardIndex() < this.locations().length - 1
    ) {
      this.currentCardIndex.set(this.currentCardIndex() + 1);
      this.swipe.emit(this.locations()[this.currentCardIndex()]);
    }
  }

  private moveToPreviousCard(): void {
    if (!this.isVertical() && this.currentCardIndex() > 0) {
      this.currentCardIndex.set(this.currentCardIndex() - 1);
      this.swipe.emit(this.locations()[this.currentCardIndex()]);
    }
  }
}
