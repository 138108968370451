import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlContainer, FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { isNotNil } from '@core/is-not-nil';
import { Address, Nil } from '@model';
import { LatLng, LatLngBounds } from '@model/geography';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddressPickerComponent } from '@ui/address-picker';
import {
  deserializeAddress,
  serializeAddress,
} from '@ui/address-picker/address-picker.utils';
import {
  AbstractFormFieldComponent,
  CONTROL_CONTAINER_VIEW_PROVIDER,
  FORM_FIELD_APPEARANCE,
  HIDE_REQUIRED_MARKER,
} from '@ui/form';
import { isNil } from 'lodash-es';

import { CLUSTER_MIN_ZOOM, MapOverlay } from './map.overlay';
import {
  AutoFitBounds,
  BoundsChangeEvent,
  MapDataSource,
  MapDataSourceItemData,
  MapMessages,
} from './map.types';
import { getLatLng, getMapOptions } from './map.utils';

export {} from 'google-maps';
export {} from 'markerclustererplus';

@UntilDestroy()
@Component({
  selector: 'etn-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: AbstractFormFieldComponent,
      useExisting: forwardRef(() => {
        return MapComponent;
      }),
    },
  ],
  viewProviders: [CONTROL_CONTAINER_VIEW_PROVIDER],
  imports: [
    AddressPickerComponent,
    CommonModule,
    FormsModule,
    GoogleMapsModule,
  ],
})
export class MapComponent
  extends AbstractFormFieldComponent<Address>
  implements OnChanges
{
  public constructor(
    container: ControlContainer,
    private cdr: ChangeDetectorRef,
    @Optional()
    @Inject(FORM_FIELD_APPEARANCE)
    public override appearance: MatFormFieldAppearance,
    @Optional()
    @Inject(HIDE_REQUIRED_MARKER)
    public override hideRequiredMarker: boolean,
  ) {
    super(container, appearance, hideRequiredMarker);
  }

  @Input() public messages: MapMessages | Nil;
  @HostBinding('class.readonly')
  @Input()
  public readonly = false;
  @Input() public center: LatLng | Nil;
  @Input() public dataSource: MapDataSource<MapDataSourceItemData> | Nil;
  @Input() public zoom = 21;
  @Input() public minZoom: number | Nil;
  @Input() public search = true;
  @Input() public editable = true;
  @Input() public showLabels = false;
  @Input() public autoFitBounds: AutoFitBounds = AutoFitBounds.Always;
  @Input() public userPosition: LatLng | Nil;

  @Output() public itemSelect = new EventEmitter<MapDataSourceItemData | Nil>();
  @Output() public addressChange = new EventEmitter<Address | Nil>();
  @Output() public ready = new EventEmitter<google.maps.Map>();
  @Output() public boundsChange = new EventEmitter<BoundsChangeEvent>();

  @ViewChild('mapContainer', { static: true }) private mapContainer:
    | ElementRef
    | Nil;

  private map: google.maps.Map | Nil;
  private mapOverlay: MapOverlay | Nil;
  private ignoreBoundsChanged = false;
  private autoFitBoundsDone = false;

  public override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.initMap();
    if (changes.dataSource && this.dataSource) {
      this.mapOverlay?.setDataSource(this.dataSource);
      this.mapOverlay?.refresh();
      if (
        this.autoFitBounds === AutoFitBounds.Always ||
        (this.autoFitBounds === AutoFitBounds.Once && !this.autoFitBoundsDone)
      ) {
        this.ignoreBoundsChanged = true;
        this.fitBounds();
        this.autoFitBoundsDone = true;
      }
    }
    if (changes.center && this.center) {
      this.map?.setCenter(this.center);
      this.mapOverlay?.draw();
    }
    if (changes.zoom && this.zoom) {
      this.map?.setZoom(this.zoom);
    }
    if (changes.showLabels) {
      this.mapOverlay?.setShowLabels(this.showLabels);
      this.mapOverlay?.refresh();
    }

    if (isNotNil(changes.userPosition)) {
      this.mapOverlay?.setUserPosition(this.userPosition);
      this.mapOverlay?.draw();
    }
  }

  public onAddressChanged(address: Address | Nil): void {
    this.updateAddress(address);
  }

  public fitBounds(bounds?: LatLngBounds): void {
    if (isNil(bounds)) {
      if (isNotNil(this.dataSource) && this.dataSource.items.length > 0) {
        const currentBounds = new LatLngBounds();

        this.dataSource.items.forEach((item) => {
          currentBounds.extend(item.latLng);
        });

        this.map?.fitBounds(currentBounds);
      }
    } else {
      this.map?.fitBounds(bounds);
    }
  }

  private initMap(): void {
    if (
      isNil(this.map) &&
      isNotNil(this.mapContainer) &&
      isNotNil(this.center)
    ) {
      const mapOptions: google.maps.MapOptions = getMapOptions(
        this.center,
        this.zoom,
        this.minZoom ?? CLUSTER_MIN_ZOOM,
      );
      const map = new google.maps.Map(
        this.mapContainer.nativeElement,
        mapOptions,
      );

      const listener = map.addListener('tilesloaded', () => {
        this.ready.emit(map);
        listener.remove();
      });

      map.addListener('bounds_changed', () => {
        if (!this.ignoreBoundsChanged) {
          this.boundsChange.emit({
            bounds: map.getBounds(),
            userPosition: this.userPosition,
          });
        }
        this.ignoreBoundsChanged = false;
      });

      this.initMarkerLayer(map);

      this.map = map;
    }
  }

  private initMarkerLayer(map: google.maps.Map): void {
    this.mapOverlay = new MapOverlay(this.minZoom);
    this.mapOverlay.setMap(map);
    this.mapOverlay.setDataSource(this.dataSource);
    this.mapOverlay.click$.pipe(untilDestroyed(this)).subscribe((id) => {
      this.itemSelect.emit(id);
    });
    this.mapOverlay.setPlace(serializeAddress(this.value));
    this.mapOverlay.setUserPosition(this.userPosition);
    this.mapOverlay.setEditable(this.editable);
    this.mapOverlay.setShowLabels(this.showLabels);
    this.mapOverlay.placeChange$
      .pipe(untilDestroyed(this))
      .subscribe((place) => {
        this.updateAddress(deserializeAddress(place));
        this.cdr.detectChanges();
      });
  }

  private updateAddress(address: Address | Nil): void {
    this.value = address;
    if (isNil(address)) {
      this.mapOverlay?.setPlace(undefined);
      this.addressChange.emit(undefined);
    } else {
      const latLng = getLatLng(address);
      if (latLng) {
        this.map?.setCenter(latLng);
        this.map?.setZoom(this.zoom);
      }
      this.mapOverlay?.setPlace(serializeAddress(address));
      this.addressChange.emit(address);
    }

    this.mapOverlay?.draw();
  }
}
